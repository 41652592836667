<template>
  <div class="excellent">
    <Swiper></Swiper>
    <div class="intro">
      新生涯针对不同特质，不同潜能的学生，规划出了适配TA的成长路径，并铺设搭建了多个能够让学生验证自己、超越自己、引领同龄人的赛事和个性化发展的通路。
    </div>
    <el-tabs class="tabs" :tab-position="top">
      <el-tab-pane label="多元升学路经">
        <img class="tagImg1" :src="tagImg1" alt="" />
      </el-tab-pane>
      <el-tab-pane label="赛事活动">
        <div class="article">
          <p class="detail" v-for="(item, i) in messList" :key="i">
           <span class="mess">{{ item.mess }}</span> 
          </p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="研究性学习报告">
        <img class="tagImg2" :src="tagImg2" alt="" />
      </el-tab-pane>
      <el-tab-pane label="学科名师集训辅导"> 学科类名师集训辅导 </el-tab-pane>
    </el-tabs>

    <!-- <SubNav></SubNav> -->
    <!-- <div class="news">
      <MiddleBox></MiddleBox>
      <div class="right">
        <long-box></long-box>
      </div>
    </div> -->
    <!-- 小新快讯 和 行业动态动态标签 -->
    <!-- <div class="list">
      <p @click="goDetail(111)">去详情!!!!!!!!!!!</p>
    </div> -->
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
      tabPosition: "left",
      tagImg1: require("@/assets/1new/生涯培优/生涯培优1.jpg"),
      tagImg2: require("@/assets/1new/生涯培优/生涯培优3.jpg"),
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
  },
});
</script>
<style lang="scss" >
.excellent {
  .intro {
    font-size: 16px;
    line-height: 20px;
    padding: 0 120px;
    text-indent: 2em;
    font-weight: bold;
    padding-bottom: 20px;
    border-bottom: 1px solid #3ebbbd;
    margin-bottom: 30px;
  }
  .tagImg1 {
    width: 1200px;
    height: 500px;
  }
  .tagImg2 {
    width: 1200px;
    height: 1728px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00a5a8; //修改后的背景图颜色
    color: #fff;
  }
  width: 1200px;
  background-color: #fff;
  .tabs {
    // padding-bottom: 20px;
    display: block;
    width: 1200px;
    padding-left: 0px;
    margin-bottom: 40px;
    // background-color: pink;
    .article {
      width: 1200px;
      padding-left: 60px;
      margin-top: 20px;

      .detail {
        // margin-bottom: 20px;
        cursor: pointer;
        width: 100%;
        font-size: 24px;
        line-height: 44px;
        .mess {
          width: 100%;
          &:hover {
            // color: #3ebbbd;
            border-bottom: 1px solid #3ebbbd;
          }
        }
      }
    }
  }
  .el-tabs__item {
    margin-left: 20px;

    font-size: 28px;
    display: inline-block;
    width: 250px;
    height: 50px;
    text-align: center;
    font-weight: 400;
    padding: 0;
    line-height: 50px;
    color: #333;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  .el-tabs__item:hover {
    color: #00a5a8;
  }
  .el-tabs__item.is-active {
    margin-left: 20px;
    color: #fff;
    width: 250px;
    line-height: 50px;
    padding: 0px;
    height: 50px;
    box-sizing: border-box;
    border: none;
    font-weight: 400;
    border-radius: 20px;
    text-align: center;
    background-color: #00a5a8;
    margin-bottom: 20px;
    &:nth-child(2) {
      width: 300px;
    }
  }
  .el-tabs__active-bar {
    background: #f0f0f0;

    /* width: 30px; */
  }
}
</style>
